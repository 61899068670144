<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    sub-title-tbl="Posisi Jetty"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All & SAL) only"
    placeholder-search="Kegiatan Name"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'jetty_position',
      titleTbl: 'DATA MASTER',
      fields: [
        {
          key: 'position',
          label: 'Jetty Position',
          sortable: true,
          placeholder: 'Jetty Position',
          input: { type: 'input-noarea' },
          searchModel: 'position',
          search: {
            type: 'text',
          },
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'created_at',
          search: {
            type: 'date',
          },
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: 'date',
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'updated_at',
          search: {
            type: 'date',
          },
        },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        keg_name: '',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.jetty_positions
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
